// React stuff
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// App state & scenarios
import { AppControllerInstance, AppEventTypes } from '../../utils/AppController';
import { SCENARIO_EVENTS } from '../../utils/enums';
import { mapsRerouteScenario } from '../../utils/Scenarios';

// Custom components
import Toast from '../Toast/Toast';

// Images
import mapZoomedOut1 from '../../assets/img/maps/map-zoomed-out-1.png';
import mapZoomedOut2 from '../../assets/img/maps/map-zoomed-out-2.png';
import mapZoomedOut3 from '../../assets/img/maps/map-zoomed-out-3.png';
// import car from '../../assets/icons/car.svg';
import calendar from '../../assets/icons/calendar.svg';
import woman1 from '../../assets/img/users/woman1.png';
import locationIcon from '../../assets/icons/location-alt.svg';
import weatherIcon from '../../assets/icons/weather.svg';
import skypeIcon from '../../assets/icons/skype.svg';
import meetingParticipants from '../../assets/img/maps/meeting-participants.png';

// Style
import './MapsApp.scss';

const MapsApp = () => {
    // Get global react app state and store it in local state
    const [appState, setAppState] = useState(AppControllerInstance.getAppState());
    const [remPixelValue, setRemPixelValue] = useState(0.55 * window.innerHeight * 0.01);

    // Component Lifecycle Methods - Hooks version!
    // Subscribe / unsubscribe to global app state updates
    useEffect(() => {
        const handleAppStateChanged = () => {
            setAppState(AppControllerInstance.getAppState());
        };
        AppControllerInstance.addListener(AppEventTypes.appState, handleAppStateChanged);

        // Component did unmount
        return () => AppControllerInstance.removeListener(AppEventTypes.appState, handleAppStateChanged);
    }, [setAppState]);

    useEffect(() => {
        // Define callback on resize to set rem pixel value
        function handleResize() {
            // Take the root fontsize, then chop of the "vh", then multiply by window height to get pixel rem value
            const fontSizeRem = appState.isFullscreen ? 0.78 : 0.55;
            setRemPixelValue(fontSizeRem * window.innerHeight * 0.01);
        }

        // Listen to onresize
        window.addEventListener('resize', handleResize);

        // Cleanup listener
        return () => window.removeEventListener('resize', handleResize);
    }, [appState.isFullscreen, setRemPixelValue]);

    useEffect(() => {
        const fontSizeRem = appState.isFullscreen ? 0.78 : 0.55;
        setRemPixelValue(fontSizeRem * window.innerHeight * 0.01);
    }, [appState.isFullscreen]);

    const currentTime = new Date().toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric' });
    const timeIn35Mins = new Date(new Date().getTime() + 35 * 60000).toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric' });

    const mapImages = {
        [SCENARIO_EVENTS.INITIAL_LOAD]: mapZoomedOut1,
        [SCENARIO_EVENTS.TRIGGER_VIEW_CHANGE]: mapZoomedOut1,
        [SCENARIO_EVENTS.MAPS_INITIAL]: mapZoomedOut1,
        [SCENARIO_EVENTS.MAPS_REROUTE_TOAST]: mapZoomedOut2,
        [SCENARIO_EVENTS.MAPS_CHOOSING_REROUTE]: mapZoomedOut3,
        [SCENARIO_EVENTS.MAPS_REROUTED]: mapZoomedOut3
    };

    return (
        <div className="MapsApp">
            <motion.div
                drag={
                    appState.currentScenarioEvent?.eventType !== SCENARIO_EVENTS.MAPS_REROUTE_TOAST &&
                    appState.currentScenarioEvent.eventType !== SCENARIO_EVENTS.MAPS_CHOOSING_REROUTE
                }
                dragConstraints={{
                    top: -210 * remPixelValue,
                    left: -240 * remPixelValue,
                    right: -130 * remPixelValue,
                    bottom: -110 * remPixelValue
                }}
                initial={{ x: -192 * remPixelValue, y: -215 * remPixelValue, scale: 0.5 }}
                animate={
                    appState.currentScenarioEvent.eventType !== SCENARIO_EVENTS.MAPS_REROUTE_TOAST &&
                    appState.currentScenarioEvent.eventType !== SCENARIO_EVENTS.MAPS_CHOOSING_REROUTE
                        ? { x: -192 * remPixelValue, y: -215 * remPixelValue, scale: 0.5 } // Default
                        : { x: -183 * remPixelValue, y: -147.5 * remPixelValue, scale: 0.34 } // Reroute suggestion
                }
                transition={{ type: 'tween', ease: 'easeInOut', duration: 1.5, delay: 0.5 }}
                className="MapDraggableContainer"
            >
                <img src={mapImages[appState.currentScenarioEvent.eventType]} alt="" className="MapImage" />
                {/* <img src={car} alt="" className="CarIcon" /> */}
            </motion.div>
            <div className="TopBar">
                <div className="StatusContainer">
                    <img alt="" src={woman1} />
                    <div className="RouteInfoContainer">
                        <h2>
                            Gothenburg
                            <img alt="" src={locationIcon} />
                        </h2>
                        <p>E6 to Gothenburg</p>
                    </div>
                </div>
                <div className="EnvironmentContainer">
                    <img alt="" src={weatherIcon} className="WeatherIcon" />
                    <span>14 C</span>
                    <span>{currentTime}</span>
                </div>
            </div>

            <div className="BottomBar">
                <div className="Info TimeLeft">
                    <span className="Quantity">0:35</span>
                    <span className="Unit">Hours</span>
                </div>
                <div className="Info DistanceLeft">
                    <span className="Quantity">93,5</span>
                    <span className="Unit">KM</span>
                </div>
                <div className="Info ArrivalTime">
                    <span className="Quantity">{timeIn35Mins}</span>
                    <span className="Unit">Arrival</span>
                </div>
                <button
                    className="ScenarioStartTrigger"
                    onClick={() => {
                        AppControllerInstance.setScenarioEvent(mapsRerouteScenario[1]);
                    }}
                />
            </div>

            <AnimatePresence>
                {(appState.currentScenarioEvent?.eventType === SCENARIO_EVENTS.MAPS_REROUTE_TOAST ||
                    appState.currentScenarioEvent?.eventType === SCENARIO_EVENTS.MAPS_CHOOSING_REROUTE) && (
                    <Toast
                        icon={skypeIcon}
                        descriptionBefore="Poor Connectivity"
                        title="Upcoming meeting in 30 min"
                        descriptionAfter={
                            <>
                                Sync with finance <img src={calendar} className="CalendarIcon" alt="" />
                            </>
                        }
                        additionalContent={<img src={meetingParticipants} className="MeetingParticipants" alt="" />}
                        buttonText="Reroute"
                        onPrimaryAction={() => {
                            AppControllerInstance.setScenarioEvent(mapsRerouteScenario[2]);
                            setTimeout(() => {
                                AppControllerInstance.setScenarioEvent(mapsRerouteScenario[3]);
                            }, appState.currentScenarioEvent?.duration);
                        }}
                        dismissText="Stay On Route"
                        onDismiss={() => AppControllerInstance.setScenarioEvent(mapsRerouteScenario[0])}
                        isInProgress={appState.currentScenarioEvent?.eventType === SCENARIO_EVENTS.MAPS_CHOOSING_REROUTE}
                        progressDuration={appState.currentScenarioEvent?.duration}
                        progressText="Rerouting"
                    />
                )}
            </AnimatePresence>
        </div>
    );
};

export default MapsApp;
