// React stuff
import React, { Component } from 'react';

// Global App State
import { AppControllerInstance, AppEventTypes } from './utils/AppController';

// Custom Components
import DeviceContainer from './components/DeviceContainer/DeviceContainer';
import PresentationContainer from './components/PresentationContainer/PresentationContainer';

// Style
import './App.scss';

class App extends Component {
    // Unused global app state
    // Good to keep around for reference?
    // Could remove later

    constructor(props) {
        super(props);
        this.state = { appState: AppControllerInstance.getAppState() };
        this._handleAppStateChanged = this._handleAppStateChanged.bind(this);
    }

    componentDidMount() {
        AppControllerInstance.addListener(AppEventTypes.appState, this._handleAppStateChanged);
    }

    componentWillUnmount() {
        AppControllerInstance.removeListener(AppEventTypes.appState, this._handleAppStateChanged);
    }

    _handleAppStateChanged(appState) {
        this.setState({ appState: appState });
    }
    // End unused global app state

    render() {
        return (
            <div className="App">
                <DeviceContainer />
                <PresentationContainer />
            </div>
        );
    }
}

export default App;
