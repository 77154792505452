// React stuff
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// App state & scenarios
import { AppControllerInstance, AppEventTypes } from '../../utils/AppController';
import { SCENARIO_EVENTS } from '../../utils/enums';
import { otaNowScenario, otaTonightScenario } from '../../utils/Scenarios';

// Images
import woman1 from '../../assets/img/users/woman1.png';

// Style
import './OtaApp.scss';
import TimePicker from '../TimePicker/TimePicker';

let currentDateTime = {};
const OtaApp = () => {
    // Get global react app state and store it in local state
    const [appState, setAppState] = useState(AppControllerInstance.getAppState());

    const dateTimeChangedCallback = (dateTime) => {
        currentDateTime = dateTime;
    };
    // Component Lifecycle Methods - Hooks version!
    // Subscribe / unsubscribe to global app state updates
    useEffect(() => {
        const handleAppStateChanged = () => {
            setAppState(AppControllerInstance.getAppState());
        };
        AppControllerInstance.addListener(AppEventTypes.appState, handleAppStateChanged);

        // Component did unmount
        return () => AppControllerInstance.removeListener(AppEventTypes.appState, handleAppStateChanged);
    }, [setAppState]);

    return (
        <div className="OtaApp">
            <div className="TopBar">
                <img alt="" src={woman1} />
                <span className="Time">14:50</span>
            </div>
            <AnimatePresence>
                {/***************/}
                {/* OTA INITIAL */}
                {/***************/}
                {appState.currentScenarioEvent.eventType !== SCENARIO_EVENTS.OTA_SET_TIME &&
                    appState.currentScenarioEvent.eventType !== SCENARIO_EVENTS.OTA_INSTALL_NOW &&
                    appState.currentScenarioEvent.eventType !== SCENARIO_EVENTS.OTA_SCHEDULED_FINAL &&
                    appState.currentScenarioEvent.eventType !== SCENARIO_EVENTS.OTA_FINAL &&
                    appState.currentScenarioEvent.eventType !== SCENARIO_EVENTS.LOOP_ENTIRE_AUTOPLAY && (
                        <motion.div
                            className="Content"
                            key={SCENARIO_EVENTS.OTA_INITIAL}
                            initial={{ left: '-100%', opacity: 0 }}
                            animate={{ left: '0%', opacity: 1 }}
                            exit={{ left: '-100%', opacity: 0 }}
                            transition={{ type: 'tween', ease: 'anticipate', duration: 0.66 }}
                        >
                            <div className="Top">
                                <h3>Software Update</h3>
                                <div className="Divider" />
                                <h2>New Update</h2>
                                <span className="Description">Update downloaded and ready to install</span>
                            </div>
                            <div className="Bottom">
                                <button
                                    className="Primary"
                                    onClick={() => {
                                        AppControllerInstance.setScenarioEvent(otaTonightScenario[1]);
                                    }}
                                >
                                    Set Time
                                </button>
                                <button className="Secondary" onClick={() => AppControllerInstance.setScenarioEvent(otaNowScenario[1])}>
                                    Install Now
                                </button>
                            </div>
                        </motion.div>
                    )}
                {/****************/}
                {/* OTA SET TIME */}
                {/****************/}
                {appState.currentScenarioEvent.eventType === SCENARIO_EVENTS.OTA_SET_TIME && (
                    <motion.div
                        className="Content"
                        key={SCENARIO_EVENTS.OTA_SET_TIME}
                        initial={{ left: '100%', opacity: 0 }}
                        animate={{ left: '0%', opacity: 1 }}
                        exit={() => {
                            switch (appState.currentScenarioEvent.eventType) {
                                case SCENARIO_EVENTS.OTA_INITIAL:
                                    return { left: '100%', opacity: 0 };
                                default:
                                    return { left: '-100%', opacity: 0 };
                            }
                        }}
                        transition={{ type: 'tween', ease: 'anticipate', duration: 0.66 }}
                    >
                        <div className="Top">
                            <h3>Software Update</h3>
                            <div className="Divider" />
                            <h2>Set Start Time</h2>
                            <span className="Description">Estimated installation time up to:</span>
                            <span className="TimeEstimate">1h 30m</span>
                            <div className="TimePickerContainer">
                                <TimePicker onSelectedDateTimeChanged={dateTimeChangedCallback} />
                            </div>
                        </div>
                        <div className="Bottom">
                            <button className="Primary" onClick={() => AppControllerInstance.setScenarioEvent(otaTonightScenario[2])}>
                                Confirm
                            </button>
                            <button className="Secondary" onClick={() => AppControllerInstance.setScenarioEvent(otaTonightScenario[0])}>
                                Cancel
                            </button>
                        </div>
                    </motion.div>
                )}
                {/***********************/}
                {/* OTA SCHEDULED FINAL */}
                {/***********************/}
                {(appState.currentScenarioEvent.eventType === SCENARIO_EVENTS.OTA_SCHEDULED_FINAL ||
                    appState.currentScenarioEvent.eventType ===
                        SCENARIO_EVENTS.LOOP_ENTIRE_AUTOPLAY) /* Necessary to prevent flash during autoplay */ && (
                    <motion.div
                        className="Content"
                        key={SCENARIO_EVENTS.OTA_SCHEDULED_FINAL}
                        initial={{ left: '100%', opacity: 0 }}
                        animate={{ left: '0%', opacity: 1 }}
                        exit={{ left: '-100%', opacity: 0 }}
                        transition={{ type: 'tween', ease: 'anticipate', duration: 0.66 }}
                    >
                        <div className="Top">
                            <h3>Software Update</h3>
                            <div className="Divider" />
                            <h2>Update Scheduled</h2>
                            <span className="Description">
                                Installation scheduled for {currentDateTime.day} {currentDateTime.hour} : {currentDateTime.minute}
                            </span>
                        </div>
                        <div className="Bottom">
                            <button
                                className="Secondary"
                                onClick={() => {
                                    AppControllerInstance.setScenarioEvent(otaTonightScenario[0]);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </motion.div>
                )}

                {/*******************/}
                {/* OTA INSTALL NOW */}
                {/*******************/}
                {appState.currentScenarioEvent.eventType === SCENARIO_EVENTS.OTA_INSTALL_NOW && (
                    <motion.div
                        className="Content"
                        key={SCENARIO_EVENTS.OTA_INSTALL_NOW}
                        initial={{ left: '100%', opacity: 0 }}
                        animate={{ left: '0%', opacity: 1 }}
                        exit={() => {
                            switch (appState.currentScenarioEvent.eventType) {
                                case SCENARIO_EVENTS.OTA_INITIAL:
                                    return { left: '100%', opacity: 0 };
                                default:
                                    return { left: '-100%', opacity: 0 };
                            }
                        }}
                        transition={{ type: 'tween', ease: 'anticipate', duration: 0.66 }}
                    >
                        <div className="Top">
                            <h3>Software Update</h3>
                            <div className="Divider" />
                            <h2>Ready to install</h2>
                            <span className="Description">Estimated installation time up to:</span>
                            <span className="TimeEstimate">1h 30m</span>
                        </div>
                        <div className="Bottom">
                            <span>
                                During the installation you will not be able to access or use your car. The car needs to be locked to start
                                the installation.
                            </span>
                            <button className="Primary" onClick={() => AppControllerInstance.setScenarioEvent(otaNowScenario[2])}>
                                Install Now
                            </button>
                            <button className="Secondary" onClick={() => AppControllerInstance.setScenarioEvent(otaTonightScenario[1])}>
                                Set Time
                            </button>
                            <button className="Secondary" onClick={() => AppControllerInstance.setScenarioEvent(otaTonightScenario[0])}>
                                Later
                            </button>
                        </div>
                    </motion.div>
                )}
                {/*************/}
                {/* OTA FINAL */}
                {/*************/}
                {appState.currentScenarioEvent.eventType === SCENARIO_EVENTS.OTA_FINAL && (
                    <motion.div
                        className="Content"
                        key={SCENARIO_EVENTS.OTA_FINAL}
                        initial={{ left: '100%', opacity: 0 }}
                        animate={{ left: '0%', opacity: 1 }}
                        exit={() => {
                            switch (appState.currentScenarioEvent.eventType) {
                                case SCENARIO_EVENTS.OTA_INITIAL:
                                    return { left: '100%', opacity: 0 };
                                default:
                                    return { left: '-100%', opacity: 0 };
                            }
                        }}
                        transition={{ type: 'tween', ease: 'anticipate', duration: 0.66 }}
                    >
                        <div className="Top">
                            <h3>Software Update</h3>
                            <div className="Divider" />
                            <h2>Install now</h2>
                            <span className="Description">Leave and lock the car to start the installation.</span>
                        </div>
                        <div className="Bottom">
                            <span>
                                During the installation you will not be able to access or use your car. The car needs to be locked to start
                                the installation.
                            </span>
                            <button className="Secondary" onClick={() => AppControllerInstance.setScenarioEvent(otaTonightScenario[0])}>
                                Don't Install Now
                            </button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default OtaApp;
