// React stuff
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

// Global App State
import { AppControllerInstance, AppEventTypes } from '../../utils/AppController';

// Custom Components
import AppSwitcher from '../AppSwitcher/AppSwitcher';
import AppContainer from '../AppContainer/AppContainer';
import { ReactComponent as EricssonLogo } from '../../assets/icons/ericsson-minimal.svg';

// Style
import './DeviceContainer.scss';

const DeviceContainer = () => {
    // Get global react app state and store it in local state
    const [appState, setAppState] = useState(AppControllerInstance.getAppState());

    // Subscribe / unsubscribe to global app state updates
    useEffect(() => {
        const handleAppStateChanged = () => {
            setAppState(AppControllerInstance.getAppState());
        };
        AppControllerInstance.addListener(AppEventTypes.appState, handleAppStateChanged);

        // Component did unmount
        return () => AppControllerInstance.removeListener(AppEventTypes.appState, handleAppStateChanged);
    }, [setAppState]);

    // Styles for fullscreen / windowed modes to animate between
    const screenVariants = {
        windowed: {
            height: 'var(--screen-height-windowed)',
            width: 'var(--screen-width-windowed)',
            top: 'calc((80vh - var(--screen-height-windowed) - 3rem)',
            left: 'calc((100vw - var(--screen-width-windowed))/2)',
            borderRadius: 16
        },
        fullscreen: {
            height: 'var(--screen-height-fullscreen)',
            width: 'var(--screen-width-fullscreen)',
            top: 0,
            left: 'calc((100vw - var(--screen-width-fullscreen))/2)',
            borderRadius: '0px'
        }
    };

    // Styles for app content sharp / blurry to animate between on opening app switcher
    const blurVariants = {
        sharp: {
            opacity: 1,
            filter: 'blur(0px)'
        },
        blur: {
            opacity: 0.5,
            filter: 'blur(5px)'
        }
    };

    return (
        <div className="DeviceContainer">
            {!appState.isFullscreen && <EricssonLogo className="EricssonLogoMinimal" />}
            {!appState.isFullscreen && <h4 className="EricssonTagline">Powered by Ericsson Connected Vehicle Cloud</h4>}
            {/* Div with hardware mockup background image */}
            <div className="DeviceFrame" style={{ opacity: appState.isFullscreen ? 0 : 1 }} />
            {/* Container for device content */}
            <motion.div
                variants={screenVariants}
                initial="windowed"
                animate={appState.isFullscreen ? 'fullscreen' : 'windowed'}
                className={'DeviceContent'}
                transition={{ type: 'tween', ease: 'easeInOut', duration: 0.75 }}
            >
                <motion.div
                    className="AppContentContainer"
                    variants={blurVariants}
                    initial="sharp"
                    animate={appState.appSwitcherActive ? 'blur' : 'sharp'}
                    transition={{ duration: 0.25 }}
                >
                    <AppContainer activeApp={appState.activeApp} />
                </motion.div>
                <AppSwitcher />
            </motion.div>
        </div>
    );
};

export default DeviceContainer;
