// React stuff
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Global App State
import { AppControllerInstance, AppEventTypes } from '../../utils/AppController';
import { entireScenario, mapsRerouteScenario, musicDownloadScenario, otaTonightScenario } from '../../utils/Scenarios';

// Enums
import { APPS, SCENARIO_EVENTS } from '../../utils/enums';

// Style
import './PresentationContainer.scss';

const PresentationContentPlaceholder = ({ number, name, description, id, before, after, scenarioEventButtons }) => {
    const variants = {
        initial: {
            opacity: 0,
            y: before === 'bottom' ? '50%' : '0%',
            x: before === 'right' ? '100%' : '0%'
        },

        visible: {
            opacity: 1,
            y: 0,
            x: 0
        },

        exit: {
            opacity: 0,
            y: after === 'bottom' ? '100%' : '0%',
            x: after === 'left' ? '-50%' : '0%'
        }
    };

    return (
        <motion.div
            key={id}
            className={'PresentationContentPlaceholder ' + name}
            variants={variants} // Animation states
            initial="initial"
            animate="visible"
            transition={{ type: 'tween', ease: [0.86, 0, 0.07, 1], duration: 1, delay: 0.25 }}
            exit="exit"
        >
            <div className="Meta">
                <span className="Number">{number}</span>
                <h2>{name}</h2>
            </div>
            <div className="Description">
                <p>{description}</p>
            </div>
            {scenarioEventButtons && <div className="ScenarioEventButtonsContainer">{scenarioEventButtons}</div>}
        </motion.div>
    );
};

const PresentationContent = ({ event, buttons }) => {
    return (
        <>
            {/********/}
            {/* MAPS */}
            {/********/}
            <AnimatePresence>
                {event === SCENARIO_EVENTS.MAPS_INITIAL && (
                    <PresentationContentPlaceholder
                        number="01"
                        name={
                            <span>
                                Network Aware
                                <br />
                                Navigation
                            </span>
                        }
                        description="Measuring network parameters to optimize service delivery – recommending preventive actions."
                        id={SCENARIO_EVENTS.MAPS_INITIAL}
                        before="bottom"
                        after="left"
                        scenarioEventButtons={buttons}
                    />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {(event === SCENARIO_EVENTS.MAPS_REROUTE_TOAST || event === SCENARIO_EVENTS.MAPS_CHOOSING_REROUTE) && (
                    <PresentationContentPlaceholder
                        number=""
                        name={
                            <span>
                                Network Aware
                                <br />
                                Navigation
                            </span>
                        }
                        description="Reroute to maintain your planned Skype call?"
                        id={SCENARIO_EVENTS.MAPS_REROUTE_TOAST}
                        before="right"
                        after="left"
                    />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {event === SCENARIO_EVENTS.MAPS_REROUTED && (
                    <PresentationContentPlaceholder
                        number=""
                        name={
                            <span>
                                Network Aware
                                <br />
                                Navigation
                            </span>
                        }
                        description="Rerouted to maintain your Skype call. Network aware car for greater user experience."
                        id={SCENARIO_EVENTS.MAPS_CHOOSING_REROUTE}
                        before="right"
                        after="bottom"
                    />
                )}
            </AnimatePresence>
            {/*********/}
            {/* MUSIC */}
            {/*********/}
            <AnimatePresence>
                {event === SCENARIO_EVENTS.MUSIC_INITIAL && (
                    <PresentationContentPlaceholder
                        number="02"
                        name={
                            <span>
                                Network Aware
                                <br />
                                Media
                            </span>
                        }
                        description="Measuring network parameters to optimize service delivery – recommending preventive actions."
                        id={SCENARIO_EVENTS.MUSIC_INITIAL}
                        before="bottom"
                        after="left"
                        scenarioEventButtons={buttons}
                    />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {(event === SCENARIO_EVENTS.MUSIC_DOWNLOAD_TOAST || event === SCENARIO_EVENTS.MUSIC_DOWNLOADING) && (
                    <PresentationContentPlaceholder
                        number=""
                        name={
                            <span>
                                Network Aware
                                <br />
                                Media
                            </span>
                        }
                        description="Download your playlist in advance?"
                        id={SCENARIO_EVENTS.MUSIC_DOWNLOAD_TOAST}
                        before="right"
                        after="left"
                    />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {event === SCENARIO_EVENTS.MUSIC_DOWNLOADED && (
                    <PresentationContentPlaceholder
                        number=""
                        name={
                            <span>
                                Network Aware
                                <br />
                                Media
                            </span>
                        }
                        description="Playlist downloaded to keep listening. Network aware car for greater user experience."
                        id={SCENARIO_EVENTS.MUSIC_DOWNLOADED}
                        before="right"
                        after="bottom"
                    />
                )}
            </AnimatePresence>
            {/*******/}
            {/* OTA */}
            {/*******/}
            <AnimatePresence>
                {event === SCENARIO_EVENTS.OTA_INITIAL && (
                    <PresentationContentPlaceholder
                        number="03"
                        name={
                            <span>
                                Over The Air
                                <br />
                                Software Updates
                            </span>
                        }
                        description="Enable the car to stay at its best over time with software over the air updates."
                        id={SCENARIO_EVENTS.OTA_INITIAL}
                        before="bottom"
                        after="left"
                        scenarioEventButtons={buttons}
                    />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {(event === SCENARIO_EVENTS.OTA_SET_TIME || event === SCENARIO_EVENTS.OTA_INSTALL_NOW) && (
                    <PresentationContentPlaceholder
                        number=""
                        name={
                            <span>
                                Over The Air
                                <br />
                                Software Updates
                            </span>
                        }
                        description="Updates have been downloaded to the car while driving, utilizing available connectivity."
                        id={SCENARIO_EVENTS.OTA_SET_TIME}
                        before="right"
                        after="left"
                    />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {(event === SCENARIO_EVENTS.OTA_SCHEDULED_FINAL || event === SCENARIO_EVENTS.OTA_FINAL) && (
                    <PresentationContentPlaceholder
                        number=""
                        name={
                            <span>
                                Over The Air
                                <br />
                                Software Updates
                            </span>
                        }
                        description="Ready to install at the driver's preference."
                        id={SCENARIO_EVENTS.OTA_FINAL}
                        before="right"
                        after="bottom"
                    />
                )}
            </AnimatePresence>
        </>
    );
};

const PresentationContainer = () => {
    // Get global react app state and store it in local state
    const [appState, setAppState] = useState(AppControllerInstance.getAppState());

    // Component Lifecycle Methods - Hooks version!
    // Subscribe / unsubscribe to global app state updates
    useEffect(() => {
        const handleAppStateChanged = () => {
            setAppState(AppControllerInstance.getAppState());
        };
        AppControllerInstance.addListener(AppEventTypes.appState, handleAppStateChanged);

        return () => AppControllerInstance.removeListener(AppEventTypes.appState, handleAppStateChanged);
    }, [setAppState]);

    // Effect to listen to 'a' keypress to trigger autoplay
    // listen to 'q' keypress to interrupt autoplay
    useEffect(() => {
        const handleKeydown = ({ key }) => {
            if (key === 'a') {
                if (!appState.scenarioIsRunning) {
                    AppControllerInstance.setAppSwitcherActive(false);
                    AppControllerInstance.startScenarioRunner(entireScenario);
                } else {
                    AppControllerInstance.stopScenarioRunner();
                }
            }
        };
        window.addEventListener('keydown', handleKeydown);

        // Cleanup
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    });

    const getScenarioToPlay = () => {
        switch (appState.activeApp) {
            case APPS.MAPS:
                return mapsRerouteScenario;
            case APPS.MUSIC:
                return musicDownloadScenario;
            case APPS.OTA:
                return otaTonightScenario;
            default:
                throw new Error('No valid app selected to play scenario for: ' + appState.activeApp);
        }
    };

    const ManualButton = (
        <button
            key="ManualButton"
            className="ScenarioControlButton"
            onClick={() => AppControllerInstance.setScenarioEvent(getScenarioToPlay()[1])}
        >
            Start
        </button>
    );

    const scenarioEventButtons = appState.scenarioIsRunning ? [] : [ManualButton];

    const progressVariants = {
        initial: {
            width: '0%'
        },
        full: {
            width: '100%'
        }
    };

    return !appState.isFullscreen ? (
        <div className="PresentationContainer">
            <PresentationContent event={appState.currentScenarioEvent.eventType} buttons={scenarioEventButtons} />
            <motion.div
                className="AutoplayProgress"
                variants={progressVariants}
                initial="initial"
                animate={appState.scenarioIsRunning ? 'full' : 'initial'}
                transition={{ ease: 'linear', duration: appState.scenarioIsRunning ? appState.totalAutoplayDuration / 1000 : 0 }}
            ></motion.div>
        </div>
    ) : (
        <></>
    );
};

export default PresentationContainer;
