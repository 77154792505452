// React stuff
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Enums
import { APPS } from '../../utils/enums';

// Custom Components
import MapsApp from '../MapsApp/MapsApp';
import MusicApp from '../MusicApp/MusicApp';
import OtaApp from '../OtaApp/OtaApp';

// Style
import './AppContainer.scss';

const AppWrapper = ({ children }) => {
    // States that the app animation transitions between
    const variants = {
        hidden: {
            opacity: 0,
            scale: 0.5,
            y: '100%'
        },

        visible: {
            scale: 1,
            opacity: 1,
            y: '0'
        }
    };

    return (
        <motion.div
            className="AppWrapper"
            variants={variants} // Animation states
            initial="hidden"
            animate="visible"
            // Delay the transition so it doesn't clash with the collapsing app switcher transition
            transition={{ type: 'tween', ease: 'anticipate', duration: 0.75, delay: 0.25 }}
            exit="hidden"
        >
            {children}
        </motion.div>
    );
};

const AppContainer = ({ activeApp }) => {
    return (
        <>
            <AnimatePresence>
                {activeApp === APPS.MAPS && (
                    <AppWrapper key={APPS.MAPS}>
                        <MapsApp />
                    </AppWrapper>
                )}
                {activeApp === APPS.MUSIC && (
                    <AppWrapper key={APPS.MUSIC}>
                        <MusicApp />
                    </AppWrapper>
                )}
                {activeApp === APPS.OTA && (
                    <AppWrapper key={APPS.OTA}>
                        <OtaApp />
                    </AppWrapper>
                )}
            </AnimatePresence>
        </>
    );
};

export default AppContainer;
