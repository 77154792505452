import React, { useState, useEffect, useRef } from 'react';
import Measure, { BoundingRect } from 'react-measure';

import './timepicker.scss';

const days = [' ', 'Today', 'Tomorrow', 'Thursday', 'Friday', 'Saturday', 'Sunday', '  '];
const hours = [
    ' ',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '  '
];
const minutes = [' ', '00', '10', '20', '30', '40', '50', '  '];

interface PickerItemProps {
    text: string;
    scrollPos: number;
    index: number;
    centered?: boolean;
    onSelected?: (data: string) => void;
}

interface iDimensions {
    bounds?: BoundingRect;
}

function PickerItem(props: PickerItemProps) {
    const [dimensions, setDimensions] = useState<iDimensions>({});
    let opacity = 1;
    if (dimensions?.bounds) {
        const height = dimensions.bounds.height;
        opacity = Math.max(height * 1.4 - Math.abs(props.index * height - (props.scrollPos + height)), 0) / (height * 1.4);
        if (Math.round(opacity) === 1 && props.onSelected) {
            props.onSelected(props.text);
        }
    }

    const className = props.centered ? 'pickerItem centered' : 'pickerItem';
    return (
        <Measure
            bounds
            onResize={(contentRect) => {
                setDimensions({ bounds: contentRect.bounds });
                //   setOpacity({ opacity: contentRect.bounds })
            }}
        >
            {({ measureRef }) => {
                return (
                    <div id={props.text + props.index} ref={measureRef} className={className} style={{ opacity: opacity }}>
                        {props.text}
                    </div>
                );
            }}
        </Measure>
    );
}

interface DateTime {
    day: string;
    hour: string;
    minute: string;
}

interface TimePickerProps {
    onSelectedDateTimeChanged: (dateTime: DateTime) => void;
}

let localCurrentDateTime = { day: '', hour: '', minute: '' };

export default function TimePicker(props: TimePickerProps) {
    const [dayScrollPos, setdayScrollPos] = useState(0);
    const [hourScrollPos, setHourScrollPos] = useState(0);
    const [minuteScrollPos, setMinuteScrollPos] = useState(0);

    const hourList = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // Needs to be delayed slightly to avoid transition bugs
        setTimeout(() => {
            document?.getElementById('2122')?.scrollIntoView();
            document?.getElementById('203')?.scrollIntoView();
        }, 400);
    }, []);

    const dayScrollCallback = (e: any) => {
        setdayScrollPos(e.currentTarget.scrollTop);
    };
    const hourScrollCallback = (e: any) => {
        setHourScrollPos(e.currentTarget.scrollTop);
    };
    const minuteScrollCallback = (e: any) => {
        setMinuteScrollPos(e.currentTarget.scrollTop);
    };

    const currentDayChanged = (day: string) => {
        let updateDateTime = Object.assign({}, localCurrentDateTime);
        updateDateTime.day = day;
        localCurrentDateTime = updateDateTime;
        props.onSelectedDateTimeChanged(localCurrentDateTime);
    };

    const currentHourChanged = (hour: string) => {
        let updateDateTime = Object.assign({}, localCurrentDateTime);
        updateDateTime.hour = hour;
        localCurrentDateTime = updateDateTime;
        props.onSelectedDateTimeChanged(localCurrentDateTime);
    };

    const currentMinuteChanged = (minute: string) => {
        let updateDateTime = Object.assign({}, localCurrentDateTime);
        updateDateTime.minute = minute;
        localCurrentDateTime = updateDateTime;
        props.onSelectedDateTimeChanged(localCurrentDateTime);
    };

    const dayDivs = days.map((day, i) => {
        return <PickerItem text={day} key={i} scrollPos={dayScrollPos} index={i} onSelected={currentDayChanged} />;
    });
    const hoursDivs = hours.map((hour, i) => {
        return <PickerItem text={hour} key={i} scrollPos={hourScrollPos} index={i} centered={true} onSelected={currentHourChanged} />;
    });
    const minuteDivs = minutes.map((minute, i) => {
        return <PickerItem text={minute} key={i} scrollPos={minuteScrollPos} index={i} centered={true} onSelected={currentMinuteChanged} />;
    });

    return (
        <div className="pickerRoot">
            <div className="topContainer">
                <span className="timeTitle">M</span>
                <span className="timeTitle">H</span>
            </div>
            <div className="pickerContainer">
                <div className="pickerColumn" onScroll={dayScrollCallback}>
                    {dayDivs}
                </div>
                <div className="spacer" />
                <div className="pickerColumn" ref={hourList} onScroll={hourScrollCallback}>
                    {hoursDivs}
                </div>
                <div className="colonContainer">:</div>
                <div className="pickerColumn" onScroll={minuteScrollCallback}>
                    {minuteDivs}
                </div>
                <div className="topHorizontalLine" />
                <div className="bottomHorizontalLine" />
            </div>
        </div>
    );
}
