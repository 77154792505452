import { AppControllerInstance } from './AppController';
import ScenarioEvent from '../model/ScenarioEvent';
import { mapsRerouteScenario } from './Scenarios';

import _ from 'lodash';

const EVENT_CHECK_INTERVAL = 500;

export default class ScenarioRunner {
    eventQueue: ScenarioEvent[];
    currentEvent: ScenarioEvent;
    isRunning: boolean;
    checkForNextEventTimer: any;

    constructor() {
        this.eventQueue = [];
        this.currentEvent = mapsRerouteScenario[0];
        this.isRunning = false;
    }

    public startScenario() {
        // Start by getting event from the queue.
        if (this.isRunning) {
            return false;
        }
        if (!this.updateCurrentEvent()) {
            return false;
        }
        this.setIsRunning(true);
        this.checkForNextEventLoop();
    }

    /**
     * @description This runs the scenario. It will run until the all the events in the senario have run.
     */
    private checkForNextEventLoop() {
        // If there is no event we can stop
        if (this.eventQueue.length === 0 || !this.isRunning) {
            this.setIsRunning(false);
            return false;
        }
        // console.log('*** Running scenario ***', this.currentEvent.eventType, this.currentEvent.duration);

        if (this.currentEvent.duration <= 0) {
            this.updateCurrentEvent();
        }

        // Clear the previous timer
        clearTimeout(this.checkForNextEventTimer);
        // Wait a second and then check for the next event agein.
        this.checkForNextEventTimer = setTimeout(() => {
            this.currentEvent.duration = this.currentEvent.duration - EVENT_CHECK_INTERVAL;
            this.checkForNextEventLoop();
        }, EVENT_CHECK_INTERVAL);
    }

    setCurrentEvent(event: ScenarioEvent) {
        this.currentEvent = event;
        AppControllerInstance.setScenarioEvent(this.currentEvent);
    }

    /**
     * @description This function will get the next event in the eventQueue. If there is no event in the queue
     *              the current event will be set to undefined and the scenario is done.
     */
    private updateCurrentEvent(): boolean {
        const scenarioEvent = this.eventQueue.shift();
        if (scenarioEvent) {
            this.setCurrentEvent(scenarioEvent);
            return true;
        }
        return false;
    }

    /**
     * @description Will add a event to the event queue
     * @param event the event that will be added to the event queue
     */
    public addEvent(event: ScenarioEvent) {
        this.eventQueue.push(event);
    }

    public setEventQueue(eventQueue: ScenarioEvent[]) {
        // (Shallow) clone eventQueue, so we don't destroy the original eventQueue by shifting
        this.eventQueue = _.cloneDeep(eventQueue);
    }

    private setIsRunning(isRunning: boolean) {
        this.isRunning = isRunning;
        AppControllerInstance.setScenarioIsRunning(isRunning);
    }

    public stopScenario() {
        this.setIsRunning(false);
    }

    getIsRunning(): boolean {
        return this.isRunning;
    }
}
