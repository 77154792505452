// React stuff
import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// App state & scenarios
import { AppControllerInstance, AppEventTypes } from '../../utils/AppController';
import { SCENARIO_EVENTS } from '../../utils/enums';
import { musicDownloadScenario } from '../../utils/Scenarios';

// Custom Components
import Toast from '../Toast/Toast';

// Images
import woman1 from '../../assets/img/users/woman1.png';
import prev from '../../assets/icons/prev.svg';
import next from '../../assets/icons/next.svg';
import pause from '../../assets/icons/pause.svg';
import { ReactComponent as MusicNoteIcon } from '../../assets/icons/music-note.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import more from '../../assets/icons/more.svg';
import album1 from '../../assets/img/music/album-1.png';
import album2 from '../../assets/img/music/album-2.png';
import album3 from '../../assets/img/music/album-3.png';
import album4 from '../../assets/img/music/album-4.png';
import albumPlaying from '../../assets/img/music/album-playing.png';
import waveform from '../../assets/img/music/waveform.png';
import noCoverageIcon from '../../assets/icons/no-coverage.svg';

// Style
import './MusicApp.scss';

const PlaylistItem = ({ nr, title, artist, length, isPlaying, isDownloaded }) => {
    return (
        <li className={'PlaylistItem' + (isPlaying ? ' Active' : '')}>
            <span className="SongNumber">{nr}</span>
            <div className="IconWrapper">
                <AnimatePresence>
                    {isPlaying && !isDownloaded && (
                        <motion.div
                            className="MusicAnimationWrapper"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.25 }}
                        >
                            <MusicNoteIcon className="MusicNoteIcon" />
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {isDownloaded && (
                        <motion.div
                            className="DownloadAnimationWrapper"
                            initial={{ y: '2.5rem', opacity: 0 }}
                            animate={{ y: '0rem', opacity: 1 }}
                            exit={{ y: '2.5rem', opacity: 0 }}
                            transition={{ duration: 0.25, delay: nr, type: 'tween', ease: 'backOut' }}
                        >
                            <DownloadIcon className="DownloadIcon" />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <div className="SongTitleAndArtistNameContainer">
                <h3 className="SongTitle">{title}</h3>
                <h4 className="ArtistName">{artist}</h4>
            </div>
            <span className="SongLength">{length}</span>
            <button className="MoreActions">
                <img src={more} alt="" />
            </button>
        </li>
    );
};

const SimilarArtistItem = ({ img, artist, album }) => {
    return (
        <li>
            <img src={img} alt="" />
            <div>
                <h3 className="ArtistName">{artist}</h3>
                <h4 className="AlbumName">{album}</h4>
            </div>
        </li>
    );
};

const MusicApp = () => {
    // Get global react app state and store it in local state
    const [appState, setAppState] = useState(AppControllerInstance.getAppState());

    // Component Lifecycle Methods - Hooks version!
    // Subscribe / unsubscribe to global app state updates
    useEffect(() => {
        const handleAppStateChanged = () => {
            setAppState(AppControllerInstance.getAppState());
        };
        AppControllerInstance.addListener(AppEventTypes.appState, handleAppStateChanged);

        // Component did unmount
        return () => AppControllerInstance.removeListener(AppEventTypes.appState, handleAppStateChanged);
    }, [setAppState]);

    const isDownloaded =
        appState.currentScenarioEvent.eventType === SCENARIO_EVENTS.MUSIC_DOWNLOADING ||
        appState.currentScenarioEvent.eventType === SCENARIO_EVENTS.MUSIC_DOWNLOADED;

    return (
        <div className="MusicApp">
            <div className="TopBar">
                <img alt="" src={woman1} />
                <span>Now Playing</span>
                <span className="Time">14:50</span>
            </div>
            <div className="NowPlayingContainer">
                <div className="ControlsContainer">
                    <button className="Previous">
                        <img src={prev} alt="" />
                    </button>
                    <button className="PlayPause">
                        <img src={albumPlaying} alt="" className="PlayingAlbumArt" />
                        <img src={pause} alt="" className="PlayPauseIcon" />
                    </button>
                    <button className="Next">
                        <img src={next} alt="" />
                    </button>
                </div>
                <div className="MetadataContainer">
                    <h2>Deep Kilimanjaro</h2>
                    <h3>Soonie</h3>
                    <div className="WaveformContainer">
                        <span>0:14</span>
                        <img src={waveform} alt="" />
                        <span>6:54</span>
                    </div>
                </div>
            </div>
            <div className="PlaylistAndSimilarArtistsContainer">
                <div className="PlaylistContainer">
                    <h2>Playlist</h2>
                    <div className="Divider" />
                    <ul>
                        <PlaylistItem nr="1" title="Ocean" artist="Larnie" length="05:45" isDownloaded={isDownloaded} />
                        <PlaylistItem
                            nr="2"
                            title="Soonie"
                            artist="Deep Kilimanjaro"
                            length="06:10"
                            isPlaying
                            isDownloaded={isDownloaded}
                        />
                        <PlaylistItem nr="3" title="Skyfire" artist="Soonie" length="05:32" isDownloaded={isDownloaded} />
                        <PlaylistItem nr="4" title="The Fusion" artist="Skauv" length="04:13" isDownloaded={isDownloaded} />
                        <PlaylistItem nr="5" title="Alone" artist="High Hopes" length="03:25" isDownloaded={isDownloaded} />
                        <PlaylistItem nr="6" title="Ocean" artist="Larnie" length="05:45" isDownloaded={isDownloaded} />
                        <PlaylistItem nr="7" title="Bandit" artist="Skauv" length="06:10" isDownloaded={isDownloaded} />
                        <PlaylistItem nr="8" title="Skyfire" artist="Soonie" length="05:32" isDownloaded={isDownloaded} />
                        <PlaylistItem nr="9" title="The Fusion" artist="Soonie" length="04:13" isDownloaded={isDownloaded} />
                        <PlaylistItem nr="10" title="Alone" artist="High Hopes" length="03:25" isDownloaded={isDownloaded} />
                    </ul>
                </div>
                <div className="SimilarArtistsContainer">
                    <h2>Similar Artists</h2>
                    <ul>
                        <SimilarArtistItem img={album1} artist="Pendulum" album="Deep" />
                        <SimilarArtistItem img={album2} artist="Lador" album="Pineapple" />
                        <SimilarArtistItem img={album3} artist="Lumi" album="Snake" />
                        <SimilarArtistItem img={album4} artist="Lumi" album="Snake" />
                        <SimilarArtistItem img={album1} artist="Pendulum" album="Deep" />
                        <SimilarArtistItem img={album2} artist="Lador" album="Pineapple" />
                        <SimilarArtistItem img={album3} artist="Lumi" album="Snake" />
                        <SimilarArtistItem img={album4} artist="Lumi" album="Snake" />
                    </ul>
                </div>
                <button
                    className="ScenarioStartTrigger"
                    onClick={() => {
                        AppControllerInstance.setScenarioEvent(musicDownloadScenario[1]);
                    }}
                />
            </div>
            <AnimatePresence>
                {(appState.currentScenarioEvent?.eventType === SCENARIO_EVENTS.MUSIC_DOWNLOAD_TOAST ||
                    appState.currentScenarioEvent?.eventType === SCENARIO_EVENTS.MUSIC_DOWNLOADING) && (
                    <Toast
                        icon={noCoverageIcon}
                        title="Approaching Area with Poor Connectivity in 10 min"
                        descriptionAfter="Download your playlist to keep listening"
                        additionalContent
                        buttonText="Download"
                        onPrimaryAction={() => {
                            AppControllerInstance.setScenarioEvent(musicDownloadScenario[2]);
                            setTimeout(() => {
                                AppControllerInstance.setScenarioEvent(musicDownloadScenario[3]);
                            }, appState.currentScenarioEvent?.duration);
                        }}
                        dismissText="Don't Download"
                        onDismiss={() => AppControllerInstance.setScenarioEvent(musicDownloadScenario[0])}
                        isInProgress={appState.currentScenarioEvent?.eventType === SCENARIO_EVENTS.MUSIC_DOWNLOADING}
                        progressDuration={appState.currentScenarioEvent?.duration}
                        progressText="Downloading"
                    />
                )}
            </AnimatePresence>
        </div>
    );
};

export default MusicApp;
